export default {
    main_manager: [
        {
            action: ['add', 'edit', 'delete'],
            subject: ['Task']
        }
    ],
    admin: [
        {
            action: ['add', 'edit', 'delete'],
            subject: ['Task']
        }
    ],
    manager: [
        {
            action: ['view', 'complete'],
            subject: ['Task']
        }
    ],
    guest: [
    ]
};
